<template>
  <ul class="Navbar">
    <li>
      <img src="@/assets/img/kc-logo.png" />
    </li>
    <li>KingsChat</li>

    <li class="Navbar__hamburger" @click="toggleNav()">
      <div></div>
      <div></div>
      <div></div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Navbar',

  methods: {
    toggleNav() {
      this.$emit('toggle-nav');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/stylesheets/components/common/navigation/Navbar.scss';
</style>
